<template>
    <div class=" flex gap-1 h-screen">
        <header class=" xl:w-1/6 pt-4 px-2">
            <PersionInfo />
        </header>
        <main class=" flex-1 overflow-auto bg-white" @scroll="(e)=>$emit('scroll',e)">
            <slot></slot>
        </main>
        <aside class=" xl:w-1/6 pt-4 px-2 flex flex-col gap-4">
            <PersionColumn />
        </aside>
    </div>
</template>
<style lang="less" scoped>
.index-layout {
    // background: #f6f6f6;

    &__title {
        height: 64px;
        color: #222226;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;

        position: sticky;
        top: 0px;
        z-index: 1;

        .extra {
            // width: 120px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > ::slotted(span) {
                margin-right: 10px;
            }

            .user-info {
                cursor: pointer;
            }

            a {
                text-decoration: none;
                color: #000;
            }
        }
    }

    &__content {
        max-width: 900px;
        margin: 0 auto;
        margin-top: 10px;
    }
}
</style>