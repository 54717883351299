<template>
    <div>
        <h1 class=" text-black text-base line">专栏</h1>
        <div class=" leading-6">
            <p class=" truncate hover:underline hover:text-blue-500 cursor-pointer flex items-center gap-1"
                v-for="c in columnListData" :key="c.id">
                <a :href="`/column/${c.id}.html`">
                    <el-icon>
                        <Notebook />
                    </el-icon>
                    {{ c.title }}
                </a>
            </p>
        </div>

    </div>
</template>
<script setup lang="ts">
import { Notebook } from '@element-plus/icons-vue'

const { data: columnList } = await useFetch("/api/_column-list")
const columnListData = computed(() => unref(columnList).data || [])
</script>